<script>
import api from '@/command/api'
import TemplateHeaderTable from '../../../components/TemplateHeaderTable'
import apiTool from '@/command/apiTool'
import DrawerForm from '../../../components/DrawerForm'
import { postAction, getAction } from '@/command/netTool'
import moment from 'moment'
export default {
  name: 'FAQManagement',
  data() {
    return {
      ...api.command.getState(),
      dataId: null,
    }
  },
  mounted() {
    this.getInit()
  },
  methods: {
    getInit() {
      api.command.getList.call(this, {
        url: '/farmFaqType/page',
        current: 1,
      })
    },
    getHeader() {
      return [
        {
          name: '分类名称',
          key: 'name',
          type: 'input',
        },
        {
          label: '',
          key: '',
          type: 'text',
        },
      ]
    },
    getColumns() {
      return [
        {
          title: 'ID',
          dataIndex: 'id',
          align: 'left',
          sorter: (a, b) => a.id - b.id,
        },
        {
          dataIndex: 'name',
          title: '分类名称',
          align: 'left',
        },
        {
          dataIndex: 'sort',
          title: '排序',
          align: 'left',
          sorter: (a, b) => a.sort - b.sort,
        },
        {
          dataIndex: 'upDown',
          title: '状态',
          type: 'badge',
          filters: [
            {
              text: '上架',
              value: '0',
            },
            {
              text: '下架',
              value: '1',
            },
          ],
          onExport: (records) => {
            return ['上架', '下架'][records]
          },
          filterMultiple: false,
          render(data) {
            return {
              showDot: true,
              name: data == 0 ? '上架' : '下架',
              color: data == 0 ? 'green' : 'red',
            }
          },
        },
        {
          dataIndex: 'a9',
          title: '操作',
          type: 'buttonGroup',
          align: 'center',
          typeData: ({ records }) => {
            return [
              {
                display: true,
                name: records.upDown == '0' ? '下架' : '上架',
                onClick: () => {
                  api.command.editGET.call(this, {
                    url: `/farmFaqType/${records.upDown == '0' ? 'down' : 'up'}?id=${records.id}`,
                  })
                },
              },
              {
                display: true,
                name: '编辑',
                onClick: () => {
                  this.onRowSelect(records)
                },
              },
              {
                display: true,
                name: '问题管理',
                onClick: () => {
                  this.$router.push('/contentManagement/problemManagement?id=' + records.id)
                },
              },
              {
                display: records.upDown !== '0',
                name: '删除',
                type: 'pop',
                popTitle: '该分类下所有的问题都将会删除，是否确认删除?',
                onClick: () => {
                  api.command.delDelArr.call(this, {
                    url: `/farmFaqType?id=${records.id}`,
                  })
                },
              },
            ].filter((e) => e.display)
          },
        },
      ]
    },
    getButton() {
      return [
        {
          name: '添加',
          type: 'primary',
          icon: 'plus',
          onClick: () => {
            this.onRowSelect()
          },
        },
        // {
        //   viewType: 'menu',
        //   name: '更多操作',
        //   isBatch: true,
        //   children: [
        //     {
        //       name: '批量删除',
        //       onClick: (data) => {
        //         let idList = data.map((e) => e.id)
        //         // return api.command.delPostArr.call(this, {
        //         //   url: '/market/farmProductSpike/deleteBatch',
        //         //   params: { idList },
        //         // })
        //       },
        //     },
        //     {
        //       name: '批量上架',
        //       onClick: (data) => {
        //         let idList = data.map((e) => e.id)
        //         // return api.command.editPost.call(this, {
        //         //   url: '/market/farmProductSpike/upBatch',
        //         //   params: { idList },
        //         // })
        //       },
        //     },
        //     {
        //       name: '批量下架',
        //       onClick: (data) => {
        //         let idList = data.map((e) => e.id)
        //         // return api.command.editPost.call(this, {
        //         //   url: '/market/farmProductSpike/downBatch',
        //         //   params: { idList },
        //         // })
        //       },
        //     },
        //   ],
        // },
      ]
    },
    getDrawerForm(source, formData) {
      const form = [
        {
          form: [
            {
              name: '分类名称',
              type: 'input',
              key: 'name',
              maxLength: 6,
              showCount: true,
              rules: [
                {
                  required: true,
                  type: 'string',
                },
              ],
            },
            {
              name: '排序',
              type: 'inputNumber',
              key: 'sort',
              props: {
                placeholder: '请输入0-100的数字',
                min: 0,
                max: 100,
              },
            },
          ],
        },
      ]
      return form
    },
    onRowSelect(records = {}) {
      if (records.id) {
        this.dataId = records.id
        getAction('/farmFaqType/detail?id=' + records.id).then((result) => {
          if (result.code == 200) {
            this.handleStatePopup(result.data)
          } else {
            this.$message.error(result.msg)
          }
        })
      } else {
        this.dataId = null
        this.handleStatePopup()
      }
    },
    handleStatePopup(
      source = {
        sort: '',
        name: '',
      }
    ) {
      let that = this
      apiTool.showDrawer({
        title: '新增分类',
        width: '720px',
        view: DrawerForm,
        viewProps: {
          data: (formData) => that.getDrawerForm(source, formData),
          form: source,
          formProps: {
            layout: 'horizontal',
            cols: 24,
            labelCol: { span: 3 },
            wrapperCol: { span: 21 },
          },
        },
        success: ({ data, setHidden }) => {
          if (JSON.stringify(data) == '{}') return
          console.log('data', data)
          // return
          api.command[!that.dataId ? 'create' : 'edit']
            .call(that, {
              url: `/farmFaqType`,
              params: {
                ...data,
                id: that.dataId,
              },
            })
            .then(setHidden)
        },
        foot: ({ close, submit }) => {
          return [
            {
              name: '取消',
              onClick: close,
            },
            {
              name: '确认',
              type: 'primary',
              onClick: submit,
            },
          ]
        },
      })
    },
  },
  render() {
    return (
      <TemplateHeaderTable
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        button={this.getButton()}
        records={this.records}
      />
    )
  },
}
</script>
